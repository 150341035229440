.bubble:nth-child(1) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 37px;
    height: 37px;
    left: 78vw;
    bottom: 59%;
    animation: move1 infinite 14s;
}

@-moz-keyframes move1 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 29%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move1 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 29%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@-o-keyframes move1 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 29%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@keyframes move1 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 29%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(2) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 94px;
    height: 94px;
    left: 37vw;
    bottom: 15%;
    animation: move2 infinite 13s;
}

@-moz-keyframes move2 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move2 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

@-o-keyframes move2 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

@keyframes move2 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(3) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 94px;
    height: 94px;
    left: 35vw;
    bottom: 85%;
    animation: move3 infinite 14s;
}

@-moz-keyframes move3 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move3 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@-o-keyframes move3 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@keyframes move3 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(4) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 25px;
    height: 25px;
    left: 20vw;
    bottom: 32%;
    animation: move4 infinite 11s;
}

@-moz-keyframes move4 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move4 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

@-o-keyframes move4 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

@keyframes move4 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(5) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 57px;
    height: 57px;
    left: 21vw;
    bottom: 51%;
    animation: move5 infinite 13s;
}

@-moz-keyframes move5 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 57%;
        transform: translate(54px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move5 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 57%;
        transform: translate(54px, 0);
        opacity: 0;
    }
}

@-o-keyframes move5 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 57%;
        transform: translate(54px, 0);
        opacity: 0;
    }
}

@keyframes move5 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 57%;
        transform: translate(54px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(6) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 34px;
    height: 34px;
    left: 45vw;
    bottom: 86%;
    animation: move6 infinite 12s;
}

@-moz-keyframes move6 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 44%;
        transform: translate(158px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move6 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 44%;
        transform: translate(158px, 0);
        opacity: 0;
    }
}

@-o-keyframes move6 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 44%;
        transform: translate(158px, 0);
        opacity: 0;
    }
}

@keyframes move6 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 44%;
        transform: translate(158px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(7) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 67px;
    height: 67px;
    left: 77vw;
    bottom: 32%;
    animation: move7 infinite 12s;
}

@-moz-keyframes move7 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 92%;
        transform: translate(21px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move7 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 92%;
        transform: translate(21px, 0);
        opacity: 0;
    }
}

@-o-keyframes move7 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 92%;
        transform: translate(21px, 0);
        opacity: 0;
    }
}

@keyframes move7 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 92%;
        transform: translate(21px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(8) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 18px;
    height: 18px;
    left: 93vw;
    bottom: 67%;
    animation: move8 infinite 11s;
}

@-moz-keyframes move8 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(43px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move8 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(43px, 0);
        opacity: 0;
    }
}

@-o-keyframes move8 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(43px, 0);
        opacity: 0;
    }
}

@keyframes move8 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(43px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(9) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 84px;
    height: 84px;
    left: 78vw;
    bottom: 15%;
    animation: move9 infinite 10s;
}

@-moz-keyframes move9 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 11%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move9 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 11%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@-o-keyframes move9 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 11%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@keyframes move9 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 11%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(10) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 62px;
    height: 62px;
    left: 4vw;
    bottom: 16%;
    animation: move10 infinite 10s;
}

@-moz-keyframes move10 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 59%;
        transform: translate(7px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move10 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 59%;
        transform: translate(7px, 0);
        opacity: 0;
    }
}

@-o-keyframes move10 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 59%;
        transform: translate(7px, 0);
        opacity: 0;
    }
}

@keyframes move10 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 59%;
        transform: translate(7px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(11) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 36px;
    height: 36px;
    left: 3vw;
    bottom: 57%;
    animation: move11 infinite 15s;
}

@-moz-keyframes move11 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move11 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@-o-keyframes move11 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

@keyframes move11 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(124px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(12) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 80px;
    height: 80px;
    left: 15vw;
    bottom: 95%;
    animation: move12 infinite 12s;
}

@-moz-keyframes move12 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(-62px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move12 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(-62px, 0);
        opacity: 0;
    }
}

@-o-keyframes move12 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(-62px, 0);
        opacity: 0;
    }
}

@keyframes move12 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 77%;
        transform: translate(-62px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(13) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 81px;
    height: 81px;
    left: 84vw;
    bottom: 77%;
    animation: move13 infinite 11s;
}

@-moz-keyframes move13 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 21%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move13 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 21%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@-o-keyframes move13 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 21%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@keyframes move13 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 21%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(14) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 73px;
    height: 73px;
    left: 13vw;
    bottom: 7%;
    animation: move14 infinite 14s;
}

@-moz-keyframes move14 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 83%;
        transform: translate(170px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move14 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 83%;
        transform: translate(170px, 0);
        opacity: 0;
    }
}

@-o-keyframes move14 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 83%;
        transform: translate(170px, 0);
        opacity: 0;
    }
}

@keyframes move14 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 83%;
        transform: translate(170px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(15) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 75px;
    height: 75px;
    left: 97vw;
    bottom: 24%;
    animation: move15 infinite 13s;
}

@-moz-keyframes move15 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 19%;
        transform: translate(138px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move15 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 19%;
        transform: translate(138px, 0);
        opacity: 0;
    }
}

@-o-keyframes move15 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 19%;
        transform: translate(138px, 0);
        opacity: 0;
    }
}

@keyframes move15 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 19%;
        transform: translate(138px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(16) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 44px;
    height: 44px;
    left: 95vw;
    bottom: 17%;
    animation: move16 infinite 10s;
}

@-moz-keyframes move16 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-18px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move16 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-18px, 0);
        opacity: 0;
    }
}

@-o-keyframes move16 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-18px, 0);
        opacity: 0;
    }
}

@keyframes move16 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-18px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(17) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 66px;
    height: 66px;
    left: 94vw;
    bottom: 61%;
    animation: move17 infinite 12s;
}

@-moz-keyframes move17 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move17 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@-o-keyframes move17 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

@keyframes move17 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-60px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(18) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 59px;
    height: 59px;
    left: 44vw;
    bottom: 87%;
    animation: move18 infinite 11s;
}

@-moz-keyframes move18 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 26%;
        transform: translate(35px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move18 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 26%;
        transform: translate(35px, 0);
        opacity: 0;
    }
}

@-o-keyframes move18 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 26%;
        transform: translate(35px, 0);
        opacity: 0;
    }
}

@keyframes move18 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 26%;
        transform: translate(35px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(19) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 94px;
    height: 94px;
    left: 34vw;
    bottom: 86%;
    animation: move19 infinite 11s;
}

@-moz-keyframes move19 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(160px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move19 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(160px, 0);
        opacity: 0;
    }
}

@-o-keyframes move19 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(160px, 0);
        opacity: 0;
    }
}

@keyframes move19 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(160px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(20) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 64px;
    height: 64px;
    left: 10vw;
    bottom: 48%;
    animation: move20 infinite 9s;
}

@-moz-keyframes move20 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 1%;
        transform: translate(-39px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move20 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 1%;
        transform: translate(-39px, 0);
        opacity: 0;
    }
}

@-o-keyframes move20 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 1%;
        transform: translate(-39px, 0);
        opacity: 0;
    }
}

@keyframes move20 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 1%;
        transform: translate(-39px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(21) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 19px;
    height: 19px;
    left: 72vw;
    bottom: 96%;
    animation: move21 infinite 15s;
}

@-moz-keyframes move21 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 7%;
        transform: translate(77px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move21 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 7%;
        transform: translate(77px, 0);
        opacity: 0;
    }
}

@-o-keyframes move21 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 7%;
        transform: translate(77px, 0);
        opacity: 0;
    }
}

@keyframes move21 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 7%;
        transform: translate(77px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(22) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 34px;
    height: 34px;
    left: 53vw;
    bottom: 16%;
    animation: move22 infinite 9s;
}

@-moz-keyframes move22 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(69px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move22 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(69px, 0);
        opacity: 0;
    }
}

@-o-keyframes move22 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(69px, 0);
        opacity: 0;
    }
}

@keyframes move22 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(69px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(23) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 32px;
    height: 32px;
    left: 2vw;
    bottom: 42%;
    animation: move23 infinite 11s;
}

@-moz-keyframes move23 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 52%;
        transform: translate(-82px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move23 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 52%;
        transform: translate(-82px, 0);
        opacity: 0;
    }
}

@-o-keyframes move23 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 52%;
        transform: translate(-82px, 0);
        opacity: 0;
    }
}

@keyframes move23 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 52%;
        transform: translate(-82px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(24) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 44px;
    height: 44px;
    left: 88vw;
    bottom: 55%;
    animation: move24 infinite 13s;
}

@-moz-keyframes move24 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 6%;
        transform: translate(171px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move24 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 6%;
        transform: translate(171px, 0);
        opacity: 0;
    }
}

@-o-keyframes move24 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 6%;
        transform: translate(171px, 0);
        opacity: 0;
    }
}

@keyframes move24 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 6%;
        transform: translate(171px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(25) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 23px;
    height: 23px;
    left: 98vw;
    bottom: 26%;
    animation: move25 infinite 11s;
}

@-moz-keyframes move25 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 70%;
        transform: translate(-22px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move25 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 70%;
        transform: translate(-22px, 0);
        opacity: 0;
    }
}

@-o-keyframes move25 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 70%;
        transform: translate(-22px, 0);
        opacity: 0;
    }
}

@keyframes move25 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 70%;
        transform: translate(-22px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(26) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 42px;
    height: 42px;
    left: 81vw;
    bottom: 19%;
    animation: move26 infinite 15s;
}

@-moz-keyframes move26 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 68%;
        transform: translate(-87px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move26 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 68%;
        transform: translate(-87px, 0);
        opacity: 0;
    }
}

@-o-keyframes move26 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 68%;
        transform: translate(-87px, 0);
        opacity: 0;
    }
}

@keyframes move26 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 68%;
        transform: translate(-87px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(27) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 77px;
    height: 77px;
    left: 87vw;
    bottom: 14%;
    animation: move27 infinite 15s;
}

@-moz-keyframes move27 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 31%;
        transform: translate(118px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move27 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 31%;
        transform: translate(118px, 0);
        opacity: 0;
    }
}

@-o-keyframes move27 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 31%;
        transform: translate(118px, 0);
        opacity: 0;
    }
}

@keyframes move27 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 31%;
        transform: translate(118px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(28) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 81px;
    height: 81px;
    left: 46vw;
    bottom: 51%;
    animation: move28 infinite 12s;
}

@-moz-keyframes move28 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move28 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

@-o-keyframes move28 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

@keyframes move28 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(29) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 89px;
    height: 89px;
    left: 51vw;
    bottom: 92%;
    animation: move29 infinite 10s;
}

@-moz-keyframes move29 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(90px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move29 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(90px, 0);
        opacity: 0;
    }
}

@-o-keyframes move29 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(90px, 0);
        opacity: 0;
    }
}

@keyframes move29 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 79%;
        transform: translate(90px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(30) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 45px;
    height: 45px;
    left: 75vw;
    bottom: 49%;
    animation: move30 infinite 13s;
}

@-moz-keyframes move30 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(-44px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move30 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(-44px, 0);
        opacity: 0;
    }
}

@-o-keyframes move30 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(-44px, 0);
        opacity: 0;
    }
}

@keyframes move30 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 18%;
        transform: translate(-44px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(31) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 23px;
    height: 23px;
    left: 69vw;
    bottom: 24%;
    animation: move31 infinite 11s;
}

@-moz-keyframes move31 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-67px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move31 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-67px, 0);
        opacity: 0;
    }
}

@-o-keyframes move31 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-67px, 0);
        opacity: 0;
    }
}

@keyframes move31 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(-67px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(32) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 98px;
    height: 98px;
    left: 18vw;
    bottom: 71%;
    animation: move32 infinite 10s;
}

@-moz-keyframes move32 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(-27px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move32 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(-27px, 0);
        opacity: 0;
    }
}

@-o-keyframes move32 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(-27px, 0);
        opacity: 0;
    }
}

@keyframes move32 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(-27px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(33) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 51px;
    height: 51px;
    left: 100%;
    bottom: 49%;
    animation: move33 infinite 14s;
}

@-moz-keyframes move33 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 4%;
        transform: translate(163px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move33 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 4%;
        transform: translate(163px, 0);
        opacity: 0;
    }
}

@-o-keyframes move33 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 4%;
        transform: translate(163px, 0);
        opacity: 0;
    }
}

@keyframes move33 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 4%;
        transform: translate(163px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(34) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 45px;
    height: 45px;
    left: 60vw;
    bottom: 54%;
    animation: move34 infinite 14s;
}

@-moz-keyframes move34 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(143px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move34 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(143px, 0);
        opacity: 0;
    }
}

@-o-keyframes move34 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(143px, 0);
        opacity: 0;
    }
}

@keyframes move34 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 64%;
        transform: translate(143px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(35) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 70px;
    height: 70px;
    left: 69vw;
    bottom: 20%;
    animation: move35 infinite 14s;
}

@-moz-keyframes move35 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(-76px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move35 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(-76px, 0);
        opacity: 0;
    }
}

@-o-keyframes move35 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(-76px, 0);
        opacity: 0;
    }
}

@keyframes move35 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 74%;
        transform: translate(-76px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(36) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 49px;
    height: 49px;
    left: 11vw;
    bottom: 73%;
    animation: move36 infinite 11s;
}

@-moz-keyframes move36 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(131px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move36 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(131px, 0);
        opacity: 0;
    }
}

@-o-keyframes move36 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(131px, 0);
        opacity: 0;
    }
}

@keyframes move36 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 97%;
        transform: translate(131px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(37) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 39px;
    height: 39px;
    left: 42vw;
    bottom: 55%;
    animation: move37 infinite 12s;
}

@-moz-keyframes move37 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move37 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

@-o-keyframes move37 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

@keyframes move37 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(10px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(38) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 63px;
    height: 63px;
    left: 80vw;
    bottom: 67%;
    animation: move38 infinite 10s;
}

@-moz-keyframes move38 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 53%;
        transform: translate(-57px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move38 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 53%;
        transform: translate(-57px, 0);
        opacity: 0;
    }
}

@-o-keyframes move38 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 53%;
        transform: translate(-57px, 0);
        opacity: 0;
    }
}

@keyframes move38 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 53%;
        transform: translate(-57px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(39) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 54px;
    height: 54px;
    left: 82vw;
    bottom: 86%;
    animation: move39 infinite 15s;
}

@-moz-keyframes move39 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 60%;
        transform: translate(109px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move39 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 60%;
        transform: translate(109px, 0);
        opacity: 0;
    }
}

@-o-keyframes move39 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 60%;
        transform: translate(109px, 0);
        opacity: 0;
    }
}

@keyframes move39 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 60%;
        transform: translate(109px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(40) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 99px;
    height: 99px;
    left: 83vw;
    bottom: 93%;
    animation: move40 infinite 13s;
}

@-moz-keyframes move40 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 86%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move40 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 86%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

@-o-keyframes move40 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 86%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

@keyframes move40 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 86%;
        transform: translate(81px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(41) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 16px;
    height: 16px;
    left: 93vw;
    bottom: 50%;
    animation: move41 infinite 11s;
}

@-moz-keyframes move41 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(6px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move41 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(6px, 0);
        opacity: 0;
    }
}

@-o-keyframes move41 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(6px, 0);
        opacity: 0;
    }
}

@keyframes move41 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 24%;
        transform: translate(6px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(42) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 35px;
    height: 35px;
    left: 99vw;
    bottom: 9%;
    animation: move42 infinite 12s;
}

@-moz-keyframes move42 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 9%;
        transform: translate(175px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move42 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 9%;
        transform: translate(175px, 0);
        opacity: 0;
    }
}

@-o-keyframes move42 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 9%;
        transform: translate(175px, 0);
        opacity: 0;
    }
}

@keyframes move42 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 9%;
        transform: translate(175px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(43) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 55px;
    height: 55px;
    left: 81vw;
    bottom: 41%;
    animation: move43 infinite 11s;
}

@-moz-keyframes move43 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 10%;
        transform: translate(110px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move43 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 10%;
        transform: translate(110px, 0);
        opacity: 0;
    }
}

@-o-keyframes move43 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 10%;
        transform: translate(110px, 0);
        opacity: 0;
    }
}

@keyframes move43 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 10%;
        transform: translate(110px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(44) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 82px;
    height: 82px;
    left: 46vw;
    bottom: 43%;
    animation: move44 infinite 11s;
}

@-moz-keyframes move44 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(126px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move44 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(126px, 0);
        opacity: 0;
    }
}

@-o-keyframes move44 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(126px, 0);
        opacity: 0;
    }
}

@keyframes move44 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 14%;
        transform: translate(126px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(45) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 74px;
    height: 74px;
    left: 28vw;
    bottom: 93%;
    animation: move45 infinite 15s;
}

@-moz-keyframes move45 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 62%;
        transform: translate(123px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move45 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 62%;
        transform: translate(123px, 0);
        opacity: 0;
    }
}

@-o-keyframes move45 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 62%;
        transform: translate(123px, 0);
        opacity: 0;
    }
}

@keyframes move45 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 62%;
        transform: translate(123px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(46) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 38px;
    height: 38px;
    left: 51vw;
    bottom: 21%;
    animation: move46 infinite 14s;
}

@-moz-keyframes move46 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-71px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move46 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-71px, 0);
        opacity: 0;
    }
}

@-o-keyframes move46 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-71px, 0);
        opacity: 0;
    }
}

@keyframes move46 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 55%;
        transform: translate(-71px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(47) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 70px;
    height: 70px;
    left: 26vw;
    bottom: 32%;
    animation: move47 infinite 12s;
}

@-moz-keyframes move47 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 49%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move47 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 49%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

@-o-keyframes move47 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 49%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

@keyframes move47 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 49%;
        transform: translate(0px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(48) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 85px;
    height: 85px;
    left: 44vw;
    bottom: 28%;
    animation: move48 infinite 10s;
}

@-moz-keyframes move48 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(28px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move48 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(28px, 0);
        opacity: 0;
    }
}

@-o-keyframes move48 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(28px, 0);
        opacity: 0;
    }
}

@keyframes move48 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 80%;
        transform: translate(28px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(49) {
    background: radial-gradient(ellipse at center, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 75px;
    height: 75px;
    left: 47vw;
    bottom: 90%;
    animation: move49 infinite 10s;
}

@-moz-keyframes move49 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 25%;
        transform: translate(-97px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move49 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 25%;
        transform: translate(-97px, 0);
        opacity: 0;
    }
}

@-o-keyframes move49 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 25%;
        transform: translate(-97px, 0);
        opacity: 0;
    }
}

@keyframes move49 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 25%;
        transform: translate(-97px, 0);
        opacity: 0;
    }
}

.bubble:nth-child(50) {
    background: radial-gradient(ellipse at top right, #b8c6c6 0%, #30b3d3 46%, #20628c 100%);
    width: 72px;
    height: 72px;
    left: 99vw;
    bottom: 11%;
    animation: move50 infinite 12s;
}

@-moz-keyframes move50 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 76%;
        transform: translate(133px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes move50 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 76%;
        transform: translate(133px, 0);
        opacity: 0;
    }
}

@-o-keyframes move50 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 76%;
        transform: translate(133px, 0);
        opacity: 0;
    }
}

@keyframes move50 {
    0% {
        bottom: 0px;
    }

    85% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }

    98% {
        opacity: 0.05;
    }

    100% {
        bottom: 76%;
        transform: translate(133px, 0);
        opacity: 0;
    }
}