.rewards-main {
    width: 100%;
    height: 100%;
    padding: 3% 0 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.plain-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;

    z-index: 1;
}

.rewards {
    margin: 5% 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.card:hover {
    box-shadow: 0px -4px 32px -4px rgb(163, 219, 252) !important;
    border-color: aqua;
    /* background: rgba(160, 160, 160, 0.387)!important; */
}

.card {
    width: 20%;
    height: 20%;
    padding: 4% 2% 2% 2%;
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.188);
    box-shadow: 0 8px 32px 0 rgba(78, 90, 255, 0.568);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.card img {
    width: 100%;
    height: 100%;
    padding: 2% 0 0 0;
}

.card:hover img {
    width: 100%;
    height: 100%;
    padding: 0% 0 2% 0;
}

.large {
    width: 22%;
    height: 22%;
    margin: 0 3%;
}


/* .register {
    width: 100%;
    height: 100%;
    padding: 1% 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 1;
} */

@media only screen and (max-width: 768px) {
    .rewards-main {
        width: 100%;
        height: 100%;
        padding: 10% 0 0 0;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .plain-heading {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;

        z-index: 1;
    }

    .rewards {
        margin: 5% 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .card {
        width: 50%;
        height: 50%;
        padding: 4% 2% 2% 2%;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.188);
        box-shadow: 0 8px 32px 0 rgba(78, 90, 255, 0.568);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
    }

    .card img {
        width: 80%;
        height: 80%;
    }

    .large {
        width: 50%;
        height: 50%;
        margin: 10% 0;
    }

    .register {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }
}