.session {
    padding: 4% 4% 4% 4%;
    width: 40%;
    height: 40%;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4% 4%;
    z-index: 1;
    position: relative;
    max-width: 40vw;
}

.session:hover {
    box-shadow: 0px -4px 32px -4px rgb(163, 219, 252) !important;
    border-color: aqua;
    /* background: rgba(160, 160, 160, 0.387)!important; */
}

.wargames {
    /* box-shadow: 0px -4px 32px -4px rgb(252, 252, 255) !important; */
    /* background: rgba(160, 160, 160, 0.387)!important; */
    width: 60%;
    max-width: 60vw;
    padding: 1.5rem !important;
}

.wargames .logoImg {
    width: 40%;
    max-width: 40vw;
}

.wargames .logoImg:hover {
    padding: 0% 0 2% 0;
    width: 40%;
    max-width: 40vw;
}

.logoImg {
    padding: 2% 0 0% 0;
    width: 60%;
    height: 60%;
    transition: 200ms;
}

.logoImg:hover {
    padding: 0% 0 2% 0;
    width: 60%;
    height: 60%;
}

.heading-2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;

}

.text {
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
}


@media only screen and (max-width:768px) {
    .session {
        margin: 1rem 0;
        padding: 1rem 0.5rem;
        width: auto;
        height: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
    }

    .flex {
        flex-direction: column;
    }

    .logoImg {
        width: 50%;
        height: 50%;
    }

    .heading-2 {
        font-family: 'Poppins', sans-serif;
        font-size: 1.25rem;
        font-weight: 600;
        text-align: center;
        text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
        margin: 7% 0;
    }

    .wargames {
        padding: 1.5rem 1rem 0.5rem 1rem !important;
    }

    .wargames .text {
        padding: 0.5rem 0 0.5rem 0 !important;
    }

    .text {
        padding: 0 5%;
        font-family: 'Poppins', sans-serif;
        font-size: 0.85rem;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
    }
}