@import url(//fonts.googleapis.com/css?family=Lato:300:400);



.header {
    position: relative;
    text-align: right;
    background: white;
    /* background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(76,149,247, 1) 100%); */
    color: rgba(76, 149, 247, 1);
}

.inner-header {
    height: 4vh;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: row;
    align-items: end;
}

.inner-header img {
    padding: 0.5em;
    width: auto;
    height: 100%;
    z-index: 100;
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 3vh;
    max-height: 10vh;
}


/* .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    Fix for safari gap
    min-height: 100px;
    max-height: 150px;
} */

.content {
    display: none;
    position: relative;
    z-index: 0;
    height: 10vh;
    text-align: center;
    /* background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(76, 149, 247, 1) 100%); */
    background-color: #318CFE;
}


/*Shrinking for mobile*/
@media only screen and (max-width: 768px) {
    .waves {
        position: relative;
        width: 100%;
        height: 10vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 3vh;
        max-height: 8vh;
    }

    .header {
        position: relative;
        text-align: center;
        background: white;
        /* background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(76,149,247, 1) 100%); */
        color: rgba(76, 149, 247, 1);
    }



    .inner-header {
        height: 2vh;
        width: 100%;
        margin: 0;
        padding: 0;
    }



    .waves {
        position: relative;
        width: 100%;
        height: 15vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 100px;
        max-height: 150px;
    }

    .content {
        position: relative;
        height: 5vh;
        margin-bottom: -2%;
        text-align: center;
        /* background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(76, 149, 247, 1) 100%); */
        background-color: #318CFE;
    }

    @keyframes move-forever {
        0% {
            transform: translate3d(-40px, 0, 0);
        }

        100% {
            transform: translate3d(135px, 0, 0);
        }
    }
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}