.flex-container {
    margin: 2% 10%;
    padding: 20px;
    position: relative;
    display: flex;
    z-index: 2;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    backdrop-filter: blur(3px);
    border: 1px solid hsla(0, 0%, 100%, .18);
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 rgba(78, 90, 255, .568);
    background: hsla(0, 0%, 100%, .188);
}
#register{
    padding:  0 2vw;
}
.vid{
    width: fit-content;
    height: auto;
    margin: 4% auto!important;
    padding: 2vw!important;
}
.vid iframe{
    width: 80vw;
    height: 45vw;    
}
form {
    width: 50%;
    color: white;
    padding: 30px;
}

.input-labels {
    font-weight: 700;
    text-shadow: 1px 1px 5px #3b3b3b;
}

.qr-div {
    width: 30%;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
}

.qr-section {

    display: flex;
    background-color: rgb(255, 252, 252);
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.qr-text-section {
    padding: 0px 4px;
    text-align: center;
    color: #ffffff;
    z-index: 1;
}


.qr-text-heading {
    font-size: 1.8rem !important;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    /* text-shadow: 0px 1px 2px #afdddf; */
}

.qr-text-heading span {
    display: none;
}

.qr-text-info {
    font-size: 0.9rem !important;
    font-weight: 500;
}

.qr-text-info span {
    font-size: 1.1rem !important;
    font-weight: 600;
}

.qr-text-placeholder {
    padding: 0.5rem 1rem;
    color: rgb(255, 87, 87);
    text-align: center;
    font-size: 2rem;
}

.qr {
    width: 86%;
    padding: 0 7% 7% 7%;
}

.qr2 {
    width: 76%;
    padding: 5% 12% 12% 12%;
}

/* .qrWithName {
    width: 70%;
    position: absolute;
    bottom: 10;
    left: 15%;
    right: 15%;
} */

.submitSection {
    height: 120px;
}

.submitButton {
    padding: 16px 24px 32px 24px;
}

#dont-display {
    display: none;
}

select.mySelectArrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 10px 20px;
    font-size: 22px;
    cursor: pointer;
    /* Use a CSS triangle for the arrow */
    /* background-image: linear-gradient(45deg, transparent 50%, #081eeb 50%, #41c5f6 100%),
        linear-gradient(135deg, #41c5f6 0%, #081eeb 50%, transparent 50%); */
    background-image: linear-gradient(45deg, transparent 50%, #000000 50%, #5e6669 100%),
        linear-gradient(135deg, #5e6669 0%, #000000 50%, transparent 50%);
    background-position: calc(100% - 20px) center, calc(100% - 10px) center;
    background-size: 10px 10px;
    background-repeat: no-repeat;
}

input,
select {
    width: 94%;
    margin: 8px 0px;
    display: block;
    border: none;
    background: #ecf0f3;
    border-radius: 10px;
    box-shadow: inset 6px 6px 6px #d7dce0, inset -6px -6px 6px #fff;
    font-size: 1rem !important;
    height: 2rem !important;
    padding: 0.5rem 0 0.5rem 0rem;
}

input[type=text],
input[type=email],
input[type=tel] {
    padding-left: 3%;
    padding-right: 3%;
}

select {
    height: 3rem !important;
    padding: 0.5rem 0.8rem 0.5rem 0.8rem !important;
    width: 100%;
}

input[type=submit] {
    width: auto !important;
    padding: 0 3rem !important;
    margin: 2.5rem auto 0rem auto !important;
    height: 3rem !important;
    font-size: 1.2rem !important;
}

@media only screen and (max-width: 768px) {
    .flex-container {
        margin: 3% 5%;
        margin-bottom: 0;
        padding: 0;

        display: flex;
        flex-wrap: wrap-reverse;
        align-items: center;
        justify-content: center;

        backdrop-filter: blur(3px);
        border: 1px solid hsla(0, 0%, 100%, .18);
        border-radius: 10px;
        box-shadow: 0 8px 32px 0 rgba(78, 90, 255, .568);
        background: hsla(0, 0%, 100%, .188);
    }

    form {
        width: 100%;
        color: white;
        padding: 30px;
    }


    .qr-div {
        width: 90%;
        margin: 5% 10% 5% 10%;
    }

    .qr-section {

        display: flex;
        background-color: rgb(255, 252, 252);
        border-radius: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .qr-text-section {
        padding: 0px 4px;
        text-align: center;
        color: #ffffff;
        z-index: 1;
    }


    .qr-text-heading {
        font-size: 1.4rem !important;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        /* text-shadow: 0px 1px 2px #afdddf; */
    }

    .qr-text-heading span {
        display: inline;
    }

    .qr-text-info {
        font-size: 0.9rem !important;
        font-weight: 500;
    }

    .qr-text-info span {
        font-size: 1.1rem !important;
        font-weight: 600;
    }

    .qr-text-placeholder {
        padding: 0.5rem 0.5rem;
        color: rgb(255, 87, 87);
        text-align: center;
        font-size: 2rem;
    }

}