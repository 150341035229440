@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200,900');

:root {
    --text-color:#006ff7;
    --shadow-color: hsla(210, 40%, 52%, .4);
    --btn-color: #e9effb;
    --bg-color: #141218;
}

.btn-hover {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
    width: auto;
    padding: 1.25% 4%;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: auto;
    text-align: center;
    /* border: none;
   */
    background-size: 300% 100%;
    border-radius: 50px;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:hover {
    background-position: 100% 0;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    background-image: linear-gradient(to right, #25aae1, #40e495, #59ddce, #2cd6ec);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.btn-hover.color-2 {
    background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
    box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.btn-hover.color-3 {
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.btn-hover.color-4 {
    background-image: linear-gradient(to right, #fc6076, #ff9a44, #ef9d43, #e75516);
    box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
}

.btn-hover.color-5 {
    background-image: linear-gradient(to right, #1ab142, #00d12d, #00e10c, #1dca02);
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

.btn-hover.color-6 {
    background-image: linear-gradient(to right, #009245, #FCEE21, #00A8C5, #D9E021);
    box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}

.btn-hover.color-7 {
    background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

.btn-hover.color-8 {
    background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.btn-hover.color-9 {
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.btn-hover.color-10 {
    background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a, #FBB03B);
    box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.btn-hover.color-11 {
    background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
    box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
}
.register .btn-hover {
    font-size: 1.5rem;
}

.register {
    position: relative;
    bottom: 0;
    height: 80%;
    padding: 1% 0 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.b1{
    position: fixed!important;
}
.b2{
    position: relative!important;
}
.btn-flush {
    position: relative;
    bottom: 0;
    /* position: -webkit-sticky; */
        /* padding: 10px 20px; */
        padding: 1.25% 4%;
        margin: 20px;
        border: none;
        background: none;
        cursor: pointer;
        
        font-family: "Source Code Pro";
        font-weight: 900;
        text-transform: uppercase;
        font-size: 30px;
        color: var(--text-color);
    
        background-color: var(--btn-color);
        box-shadow: var(--shadow-color) 2px 2px 22px;
        border-radius: 4px;
        z-index: 0;
        overflow: hidden;
        /* text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
    position: relative;
    margin-bottom: 20px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 1.25% 4%;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: auto;
    text-align: center;
    background-image: linear-gradient(to right, #25aae1, #40e495, #59ddce, #2cd6ec);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    border-radius: 100px;
    z-index: 0;
    overflow: hidden; */
}


.right::after,
.btn-flush::after {
    content: var(--content);
    display: block;
    position: absolute;
    white-space: nowrap;
    padding: 40px 40px;
    pointer-events: none;
}

.btn-flush::after {
    font-weight: 200;
    top: -30px;
    left: -20px;
}

.right,
.left {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.right {
    left: 66%;
}

.left {
    right: 66%;
}

.right::after {
    top: -30px;
    left: calc(-66% - 20px);

    background-color: var(--bg-color);
    color: transparent;
    transition: transform .4s ease-out;
    transform: translate(0, -90%) rotate(0deg)
}

.btn-flush:hover .right::after {
    transform: translate(0, -47%) rotate(0deg)
}

.btn-flush .right:hover::after {
    transform: translate(0, -50%) rotate(-7deg)
}

.btn-flush .left:hover~.right::after {
    transform: translate(0, -50%) rotate(7deg)
}

/* bubbles */
.btn-flush::before {
    content: '';
    pointer-events: none;
    opacity: .6;
    background:
        radial-gradient(circle at 20% 35%, transparent 0, transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
        radial-gradient(circle at 75% 44%, transparent 0, transparent 2px, var(--text-color) 3px, var(--text-color) 4px, transparent 4px),
        radial-gradient(circle at 46% 52%, transparent 0, transparent 4px, var(--text-color) 5px, var(--text-color) 6px, transparent 6px);

    width: 100%;
    height: 300%;
    top: 0;
    left: 0;
    position: absolute;
    animation: bubbles 5s linear infinite both;
}

@keyframes bubbles {
    from {
        transform: translate();
    }

    to {
        transform: translate(0, -66.666%);
    }
}