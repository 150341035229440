@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
/* .colorsec {
    height: auto;
        background: linear-gradient(180deg, #318CFE 0%, #318CFE 20%, #004eae 50%, rgb(0, 83, 185) 70%, #0050B4 90%, #0050B4 100%);

} */
.pink{
  color: pink!important;
}
a{
  cursor: pointer;
}
.canvas {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #318CFE 0%, #318CFE 20%, #004eae 50%, rgb(0, 83, 185) 70%, #0050B4 90%, #0050B4 100%);
  position: relative;
  overflow: hidden;
}

/* .bubble {
    display: block;
    border-radius: 100%;
    opacity: 0.8;
    position: absolute;
} */
.bubble {
  z-index: 0;
  display: block;
  border-radius: 100%;
  opacity: 0.8;
  position: absolute;
  background: radial-gradient(ellipse at center, #ffffff 0%, #a4d5ff 46%, rgb(133, 186, 255) 100%) !important
}


body {
  margin: 0;
  padding: 0 0;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
}

p {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-size: 1rem;
  color: #333333;
}

.titlebox img {
  padding-right: 1.2rem;
  display: block;
  bottom: 0;
  width: 40%;
  height: auto;
  object-fit: contain;
  position: relative;
  z-index: 0;
  margin-bottom: 0rem;
}

.titlebox {
  z-index: 1;
  position: relative;
  padding-top: 1.5rem !important;
  width: 35vw;
  margin: 5% auto 2% auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-family: 'poppins', sans-serif; */
  /* background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(76, 149, 247, 1) 100%); */
  background: white !important;
  color: white;
  padding: 4% 8%;
  box-shadow: 0 4px 30px rgba(76, 149, 247, 1) !important;
  border-radius: 30px !important;
}

.titlebox h1 {
  color: rgba(76, 149, 247, 1);
  text-shadow: rgba(170, 190, 255, 0.784) 0em 0.025em 0.05em !important;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 4rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.titlebox h2 {
  color: rgba(76, 149, 247, 1);
  text-shadow: rgba(170, 190, 255, 0.784) 0em 0.025em 0.05em !important;
  font-family: 'Red Hat Display', sans-serif !important;
  font-style: italic;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}


@media only screen and (max-width: 768px) {

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
  }
    .pink {
font-size: 2rem;    }





  .btn-hover {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-shadow: rgb(0, 0, 0) 0em 0.025em 0.05em;
    width: auto;
    padding: 4% 8%;
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 2rem;
    height: auto;
    text-align: center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
}


/* .btn-hover:hover {
  background-position: 100% 0;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
} */
